.modal_parent{
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1050;
  left: 0px;
  background: #fffc;
}
.reason-modal.modal-body{
  display: flex;
  flex-direction: column;
}
.reason-modal.modal-body > select{
  padding: 8px 16px;
  border-radius: 4px;
}
.reason-modal-header{
  display: flex;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid #e5e5e5;
}
.reason-select-option{
  background-color: aliceblue;
  color: black;
  padding: 8px 16px !important;
}