.limitSelectDiv{
    display: flex;
    margin-top: 55px;
    justify-content: space-around;
    width: 30%;
    margin-left: auto;
    margin-right: auto;
}
.limitSelectDiv > select{
    border: 1px solid #ccc;
    border-radius: 6px;
}
.til_dateRangeInput{
    max-width: 400px;
    margin: auto;
    border: 1px solid #ced4ce;
    padding: 16px;
    border-radius: 6px;
    margin-bottom: 30px;
}
#partner_role_select{
    align-self: center;
    width: 100%;
    margin-top: 8px;
}