.kyc_details{
    width: 100%;
    padding: 10px;
}
.kyc_nameStrip{
    width: 100%;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 3px 10px #0006;
    padding: 10px 20px;
    margin-bottom: 30px;
    border-radius: 6px;
}
.kyc_nameStrip > span{
    font-size: 16px;
}
.kyc_nameStrip > span > span{
    color: red;
    font-weight: bold;
}
.kyc_nameStrip > span:nth-of-type(1){
    font-weight: bold;

}
.kyc_nav{
    display: flex;
    justify-content: space-between;
    
}
.kyc_nav > button {
    width: 20%;
    color: #016E81;
    border: 1px solid #016E81;
    background-color: white;
    padding:6px 0px;
    font-size: 14px;
    border-radius: 4px;
}
.kyc_nav > button.selected{
    background-color: #016E81;
    color: white;
}
.kyc_nav__control{
    box-shadow: 0px 3px 6px #0003;
}
.kycCard{
}
.kycCard > div{
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-top: 1px solid #ccc;
    padding: 20px 30px;
}
.kycCard > div:last-child{
    border-bottom: 1px solid #ccc;
}
.kycCard_row1{
    display: grid;
    grid-template-columns: 1fr minmax(20px, 1fr) 1fr;
    
}
.kycCard_row1 > h4{
    margin: 0px !important;
    align-self: center;
}
.kycCard_row2{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}
.kycCard_imageSubgrid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    object-fit: contain;
}
.kycCard_imageSubgrid  img{
    object-fit: contain;
    width: 200px;
    height: 150px;
}
.kycCard_imageSubgrid > a{
    width: 150px;
    margin: auto;
}
.subStatus{
    border: none !important;
    text-align: end;
    opacity: 0.6;
    padding: 20px 30px;
}
.subStatus > span {
    color: orangered;
    font-weight: bold;
}

@media (max-width: 720px) {
  .kycCard_row1{
      display: block !important;
  }
  .kycCard_row1 > *{
      margin: 10px !important;
      padding-left: 0px !important;
  }
  .kycCard_row2{
      display:  block !important;
  }
  .kycCard_row2 > *{
      margin: 10px;
  }
  .kycCard_subRow1{
      display: block !important;
  }
  .kycCard_bankDetails{
      display: block !important;
  }
}
.approved_text{
    display: flex;
    align-items: center;
    padding: 0px 30px;
    color: orangered;
    font-weight: bold;
}
.kycCard_row1 > input{
    padding: 10px;
    height: 30px;
    margin: auto 0px;
}
.approved_text > svg{
    font-size: 20px;
    margin-left: 6px;
}
.button_approve{
    border: 1px solid orange;
    background-color: orange;
    color:  white;
    max-width: 200px;
    width: 100px;
    padding: 6px 0px;
}
.button_reject{
    background-color: white;
    color: orangered;
    border: 1px solid orangered;
    max-width: 200px;
    width: 100px;
    padding: 6px 0px;
}
.address_approveControls{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}
.kycCard_subRow1{
    grid-template-columns: 5fr 1fr;
    align-items: center;
}