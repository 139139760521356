.visibility{
  display: none !important;
}
.center_children{
  display: flex;
  justify-content: center;
}
#add_reason{
  display: flex;
  flex-direction: column;
  border: 1px solid #d2d2d2;
  border-radius: 8px;
  padding: 16px;
  width: 60%;
}
#add_reason > *{
  border-radius: 8px;
  margin-bottom: 8px;
}
.reason_controls{
  display: flex;
  flex-direction: row-reverse;
}
.reason_input_element{
  border: 1px solid #ccc;
  padding: 8px 16px;
}
.reason_controls > button{
  margin-left: 16px;
}