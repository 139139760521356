
.survey hr{
    margin-top: 0px;
    margin-bottom: 14px;
    height: 1px;
    background-color: #d2d2d2;
}
.survey_subsection{
    border: 1px solid #dfdfdf;
    padding: 12px 32px 32px 32px;
}
.survey_subsection > div{
    margin-top: 16px;
    border-radius: 8px;
    border: 1px solid #dfdfdf;
    margin-top: 20px;
    border: 1px solid #ccc;
    padding: 10px 30px;
    text-transform: capitalize;
}
.survey_counter{
    font-size: 16px;
    color:#9E9E9E;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 0px;
    cursor: pointer;
}
.survey_question{
    margin-top: 14px;
    margin-bottom: 14px ;
    font-size: 18px;
    font-weight: 600;
}
.survey_response{
    font-size: 16px;
    font-weight: 500;
    color:#9E9E9E;
}
.survey .subStatus{
    border: none !important;
    text-align: end;
    opacity: 0.6;
    padding: 20px 30px;
}