.kyc_card{
    border: 1px solid #dfdfdf;
    border-radius: 8px;
    padding: 20px;
}
.mb-0{
    cursor: pointer;
}
.button_approve{
    margin-right: 16px;
    border-radius: 3px;
}
.button_reject{
    border-radius: 3px;
}